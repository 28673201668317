import React from "react";
import { Helmet } from "react-helmet";

import { Layout } from "@components";
import {
  SectionOfMainCt,
  SectionTwoCt,
  SectionThreeCt,
  SectionFourCt,
  SectionFiveCt,
} from "@templates/acquiring";

const AcquiringPage = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Интернет эквайринг для физ лиц | Сервис по подключению интернет эквайринга для физ лиц</title>
      <meta name="description" content="В поиске интернет эквайринга для физ лиц? Наш сайт поможет вам с этим. Автоматическая выдача чеков. Деньги сразу поступают на кошелек" />
      <link rel="canonical" href="https://xn--80aapgyievp4gwb.xn--p1ai/acquiring-person" />
    </Helmet>
    <Layout>
      <SectionOfMainCt h1Text="Интернет эквайринг для физических лиц без ИП"/>
      <SectionTwoCt />
      <SectionThreeCt />
      <SectionFourCt />
      <SectionFiveCt />
    </Layout>
  </>
);

export default AcquiringPage;
